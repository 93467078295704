.terms-wrapper {
    .terms-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        width: 100%;
        height: 400px;
    }
    ul {
        padding-left: 20px; /* リスト全体のインデント */
      }
    
      li {
        margin-left: 10px; /* 各リストアイテムのインデント */
      }
    .terms-sub {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 50px 0 0 0;
    }
}