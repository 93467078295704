.header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:30px;
  position: fixed;
  top: 0;
  z-index: 100;
  .icon {
    display: flex;
    justify-content: center;
    align-items: start; 
    flex-direction: column;
    text-decoration: none;
    @media screen and (max-width:600px) { 
      /*　画面サイズが480pxからはここを読み込む　*/
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 8px;
      }
    }
  }
  .sub {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    .SNS {
      display: flex;
      justify-content: center;
      align-items: center;  
      a { 
        * {
          transition: all 0.2s;
        }
        height: 25px;
        width: 25px;
        margin: 0 10px;
        
        svg {
          fill: var(--black);
        }
        &:hover {
          svg {
            fill: var(--white);
          }
        }
      }
    }  
    a {
      display: flex;
      align-items: center;
      height: 50px;
      margin: 0 10px;
      border-radius: 50vh;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        background-color: var(--unrecognizable-white);
      }
    }
  }
}
