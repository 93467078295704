.footer-wrapper {
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  margin-top: 100px;
  * {
    background-color: inherit;
    color: var(--unrecognizable-black);
  }
  .MuiDivider-root{
    background-color: var(--unrecognizable-black);
  }
  small {
    width: 100%;
    text-align: center;
  }
}
