.home-wrapper {
    .home-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        width: 100%;
        height: 100vh;
    }
    .home-contents {
        display: flex;
        flex-direction: column;
        row-gap: 200px;
        .child {
            margin: 50px 0;
            h2 {
                margin-bottom: 10px;
            }
        }
        .top {
            text-align: center;
            margin: 50px 0;
            height: 300vh;
        }

    }
}