:root {
  --black-hole           : #000000;
  --deep-black           : #222222;
  --black                : #444444;
  --unrecognizable-black : #666666;
  --gray                 : #7a7a7a;
  --unrecognizable-white : #999999;
  --white                : #bbbbbb;
  --deep-white           : #dddddd;
  --white-hole           : #ffffff;
}

* {
  /* background: var(--white-hole); */
  color: var(--black);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* for code fote */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar{
  display: none;
}


