.app-root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .contents {
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    animation-name: fadein;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform:translateX(100%)
  }
  100% {
    opacity: 1;
  }
}

